<template>
  <div>
    <div class="form-row">
      <h4>
        <em>{{ currentStepNumber }}/{{ totalSteps }}</em>
        <br>
        {{ catalogueTranslations.modalCatalogueRelatedContentHeading }}
      </h4>
    </div>
    <div class="form-row">
      <label
        for="catalogue-related-title"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueRelatedContentTitleHeading }}
      </label>
      <input
        id="catalogue-related-title"
        v-model="value.relatedContentTitle"
        :placeholder="catalogueTranslations.modalCatalogueRelatedContentTitlePlaceholder"
        type="text"
      >
    </div>
    <div class="form-row">
      <div class="tooltip-holder">
        <label
          for="catalogue-content-link"
          class="form-label"
        >
          {{ catalogueTranslations.modalCatalogueRelatedContentLinkHeading }}
        </label>
        <Tooltip
          v-if="tooltipModel.title || tooltipModel.content"
          :model="tooltipModel"
          placement="top"
        />
      </div>
      <input
        id="catalogue-content-link"
        v-model="value.relatedContentLink"
        type="url"
        :placeholder="catalogueTranslations.modalCatalogueRelatedContentLinkPlaceholder"
        :pattern="urlPattern"
        @input.once="onStartTyping('link')"
      >
    </div>
    <div class="form-row">
      <label
        for="catalogue-related-description"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueRelatedContentDescriptionHeading }}
      </label>
      <textarea
        id="catalogue-related-description"
        v-model="value.relatedContentDescription"
        cols="5"
        rows="5"
        :placeholder="catalogueTranslations.modalCatalogueRelatedContentDescriptionPlaceholder"
        @input.once="onStartTyping('relatedContentDescription')"
      />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import useCatalogue from '@/compositions/useCatalogue'
import gtmHelper from '@/helpers/GtmHelper'
import Tooltip from '@/components/tooltip.vue'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state: {
        totalSteps
      },
      currentStepNumber,
      currentStep
    } = useWizardFlow()
    const { catalogueTranslations } = useCatalogue()

    const {
      modalCatalogueRelatedContentLinkAvailableDomain,
      modalCatalogueRelatedContentLinkTooltipTitle,
      modalCatalogueRelatedContentLinkTooltipContent
    } = catalogueTranslations.value
    const availableDomains = modalCatalogueRelatedContentLinkAvailableDomain
      ? modalCatalogueRelatedContentLinkAvailableDomain.split(',')
        .map((domain) => `${domain.replace(/\\./g, '\\.')}`)
        .join('|')
      : '(?:\\w+\\.\\w{2,3})'
    const urlPattern = `^https?:\\/\\/(?:www\\.)?(?:\\w+\\.)?(?:${availableDomains})(?:\\/.*)?$`
    const tooltipModel = computed(() => ({
      title: modalCatalogueRelatedContentLinkTooltipTitle,
      content: modalCatalogueRelatedContentLinkTooltipContent
    }))

    const onStartTyping = (fieldName) => {
      gtmHelper.catalogueFieldInput(fieldName)
    }

    const onFieldChange = (fieldName, value) => {
      gtmHelper.catalogueFieldInput(fieldName, value)
    }

    return {
      catalogueTranslations,
      currentStepNumber,
      currentStep,
      totalSteps,
      urlPattern,
      tooltipModel,

      onStartTyping,
      onFieldChange
    }
  }
}
</script>
