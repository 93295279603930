export default {
  basicDetails: {
    component: 'CatalogueBasicDetails',
    isFinal: false
  },
  contactInformation: {
    component: 'CatalogueContactInformation',
    isFinal: false
  },
  relevantContent: {
    component: 'CatalogueRelatedContent',
    isFinal: false
  },
  visualDetails: {
    component: 'CatalogueVisualDetails',
    isFinal: true
  }
}
