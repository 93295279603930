<template>
  <div
    :class="{ 'breaker-block--image-only' : image && !videoId, 'breaker-block--video' : videoId }"
    class="breaker-block"
  >
    <div
      v-if="hasContent"
      class="breaker-block__content"
    >
      <strong
        v-if="text"
        class="breaker-block__text"
        v-text="text"
      />
      <a
        v-if="linkUrl && linkText"
        :href="linkUrl"
        :target="linkOpenInNewWindow ? '_blank' : '_self'"
        class="cta cta--light"
        @click="onButtonClick"
        v-text="linkText"
      />
    </div>
    <ambient-media
      :video-id="videoId"
      :thumbnail="image"
      :thumbnail-width="1680"
      :thumbnail-height="420"
    />
    <div
      v-if="showOverlay"
      :style="`opacity: ${overlayOpacity/100}`"
      class="breaker-block__overlay"
    />
  </div>
</template>

<script>
import AmbientMedia from '@/components/ambient-media.vue'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    AmbientMedia
  },
  props: {
    image: {
      type: String,
      default: ''
    },
    videoId: {
      type: String,
      default: ''
    },
    overlayOpacity: {
      type: Number,
      default: 100
    },
    text: {
      type: String,
      default: ''
    },
    linkUrl: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: ''
    },
    linkOpenInNewWindow: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      trackingData: JSON.stringify({
        event: 'breakerBlockCtaClick',
        text: this.text
      })
    }
  },
  computed: {
    hasContent() {
      return this.text || (this.linkUrl && this.linkText)
    },
    showOverlay() {
      return this.overlayOpacity > 0
    }
  },
  methods: {
    onButtonClick() {
      GtmHelper.pushDataLayer(this.trackingData)
    }
  }
}
</script>
