<template>
  <div
    id="recipe-tabs"
    class="c-recipe__tabs"
  >
    <div class="c-recipe__tab-links">
      <div class="c-recipe__tab-links__wrapper">
        <a
          v-for="tab in tabList"
          :key="tab.id"
          :class="getTabClass(tab)"
          :aria-expanded="isActiveTab(tab.id) ? 'true' : 'false'"
          class="c-recipe__tab-link"
          role="button"
          tabindex="0"
          @click.prevent="onTabLinkClick(tab.id)"
          @keyup.enter="onTabLinkClick(tab.id)"
        >
          {{ tab.name }}
        </a>
      </div>
    </div>
    <div class="c-recipe__tab-content">
      <recipe-ingredients
        v-show="isActiveTab('ingredients')"
        :ingredients="ingredients"
      />
      <recipe-nutrition
        v-show="isActiveTab('nutrition')"
        :nutrition="nutrition"
      />
    </div>
  </div>
</template>

<script>
import RecipeIngredients from './recipe-tabs-ingredients.vue'
import RecipeNutrition from './recipe-tabs-nutrition.vue'

const translations = window.arla.cms.resources.recipes.recipePage

export default {
  components: {
    RecipeIngredients,
    RecipeNutrition
  },
  props: {
    ingredients: {
      type: Object,
      required: true
    },
    nutrition: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTabId: 'ingredients'
    }
  },
  computed: {
    tabList() {
      const tabListArray = [
        {
          name: translations.ingredientsHeader,
          id: 'ingredients'
        }
      ]

      if (!this.nutrition.isEmpty) {
        tabListArray.push({
          name: translations.nutritionHeader,
          id: 'nutrition'
        })
      }

      return tabListArray
    }
  },
  watch: {
    activeTabId(newClass, currentClass) {
      const prefix = 'c-recipe--'
      document.body.classList.remove(prefix + currentClass)
      document.body.classList.add(prefix + newClass)
    }
  },
  methods: {
    getTabClass(tab) {
      let cssCombinedClass = tab.id
      if (this.activeTabId === tab.id) {
        cssCombinedClass += ' active'
      }
      return cssCombinedClass
    },
    isActiveTab(tabId) {
      return tabId === this.activeTabId
    },
    onTabLinkClick(tabId) {
      this.setActiveTab(tabId)
    },
    setActiveTab(tabId) {
      this.activeTabId = tabId
    }
  }
}
</script>
