import axios from 'axios'
import settings from '@/settings'

axios.defaults.headers['Content-Type'] = 'application/json'
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && [401].includes(error.response.status)) {
      window.location = `${window.location.origin}${window.location.pathname}${window.location.search}/#login`
    }
    return Promise.reject(error)
  }
)

export default {
  getRecipes({
    skip,
    take,
    activeCategories = [],
    terms = [],
    sorting = null,
    categoriesAnd = true,
    excluded = [],
    getTermFacets = true
  }) {
    const categories = activeCategories.map(({ uid, name }) => uid || name).join('|')
    const params = {
      skip,
      take,
      sorting,
      terms: terms.join('|'),
      categories,
      categoriesAnd,
      excludedIds: excluded.join('|'),
      getTermFacets
    }
    return axios.get(settings.apiUrls.search.recipes, { params })
  },
  getFavoriteRecipes({
    sorting = null
  }) {
    const params = {
      sorting
    }
    return axios.get(settings.apiUrls.user.favorites.recipes, { params })
  },
  getProducts({
    skip,
    take,
    activeCategories = [],
    terms = [],
    sorting = null,
    categoriesAnd = true,
    excluded = [],
    getTermFacets = true
  }) {
    const categories = activeCategories.map(({ uid, name }) => uid || name).join('|')
    const params = {
      skip,
      take,
      sorting,
      terms: terms.join('|'),
      categories,
      categoriesAnd,
      excludedEans: excluded.join('|'),
      getTermFacets
    }
    return axios.get(settings.apiUrls.search.products, { params })
  },
  getFavoriteProducts({
    sorting = null
  }) {
    const params = {
      sorting
    }
    return axios.get(settings.apiUrls.user.favorites.products, { params })
  },
  getCmsNavigation() {
    return axios.get(settings.apiUrls.Header.CmsNavigation)
  },
  selectConnectStore(storeId) {
    return axios.post(`${settings.apiUrls.Header.ConnectSelectStore}?storeId=${storeId}`)
  },
  trackCardItemClick(queryTrackId, hitId, terms = [], position, searchType) {
    const params = {
      trackId: queryTrackId,
      hitId,
      position,
      terms: terms.join('|'),
      searchType
    }
    axios.get(`${settings.apiUrls.search.trackCardItemClick}`, { params })
  },
  trackAutoSuggestionClick(data) {
    axios.get(`${settings.apiUrls.search.trackAutoSuggestionClick}`, { data })
  },
  getInspirationBlockData(targetGroupSlug) {
    const params = {
      targetGroupSlug
    }
    return axios.get(settings.apiUrls.inspirationBlockData, { params })
  },
  getArticles({
    skip, take, activeCategories = [], terms = [], sorting = null
  }) {
    const params = {
      skip,
      take,
      sorting,
      terms: terms.join('|'),
      categories: activeCategories.join('|')
    }
    return axios.get(settings.apiUrls.search.articles, { params })
  },
  getOrderMaterials({
    skip, take, activeCategories = [], terms = [], sorting = null
  }) {
    const params = {
      skip,
      take,
      sorting,
      terms: terms.join('|'),
      categories: activeCategories.join('|')
    }
    return axios.get(settings.apiUrls.search.orderMaterials, { params })
  },
  getOrderMaterialsByIds({ skip, take, ids = [] }) {
    const params = {
      skip,
      take,
      ids: ids.join('|')
    }
    return axios.get(settings.apiUrls.search.orderMaterialsByIds, { params })
  },
  getBusinessCases({
    skip,
    take,
    activeCategories = [],
    terms = [],
    sorting = null,
    categoriesAnd = true,
    excluded = []
  }) {
    const params = {
      skip,
      take,
      sorting,
      terms: terms.join('|'),
      categoriesAnd,
      excludedIds: excluded.join('|'),
      categories: activeCategories.join('|')
    }
    return axios.get(settings.apiUrls.search.businessCases, { params })
  },
  getFavoriteBusinessCases({
    sorting = null
  }) {
    const params = {
      sorting
    }
    console.log(settings.apiUrls.user.favorites.businessCases)
    return axios.get(settings.apiUrls.user.favorites.businessCases, { params })
  },
  getSearchItems({
    take,
    searchType,
    searchTypes = [],
    activeCategories = [],
    terms = [],
    sorting = null,
    catalogueId = null
  }, { skip }) {
    const categories = activeCategories.map(({ uid, name }) => uid || name).join('|')
    const params = {
      skip,
      take,
      sorting,
      searchType,
      searchtypes: searchTypes.join('|'),
      terms: terms.join('|'),
      categories,
      catalogueId
    }
    return axios.get(settings.apiUrls.search.searchitems, { params })
  },
  getProductsBottomBannerInfo() {
    return axios.get(settings.apiUrls.general.productsBottomBanner)
  },
  getAutoSuggestions(query) {
    const params = {
      query
    }
    return axios.get(settings.apiUrls.search.autoSuggestions, { params })
  },
  submitOrderMaterials(data, token) {
    return axios.post(settings.apiUrls.orderMaterials.submitOrder,
      data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  login({ customerNumber, password }) {
    const headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(settings.apiUrls.login, {
      Username: customerNumber,
      Password: password
    }, { headers })
  },
  addCartItems({ articleNumber, amount }) {
    const headers = {
      'Content-Type': 'application/json'
    }
    return axios.post(settings.apiUrls.cart.add, {
      articleNumber,
      amount
    }, { headers })
  },
  markMessageRead(id) {
    axios.get(`${settings.apiUrls.profile.markRead}${id}`)
  },
  getInfoPopupModel({
    popupId,
    hem = ''
  }) {
    const params = {
      popupId,
      hem
    }

    return axios.get('/api/sfmc/popup/', { params })
  },
  submitSurveyApi({
    popupId,
    hem,
    surveyId,
    questionId,
    answerId,
    token
  }) {
    const params = {
      popupId,
      hem,
      surveyId,
      questionId,
      answerId
    }

    const headers = {
      RequestVerificationApiToken: token
    }

    return axios.post('/api/sfmc/submit/', params, { headers })
  },
  submitCatalogue(data, token) {
    return axios.post(settings.apiUrls.catalogue,
      data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  editCatalogue(data, token, id) {
    return axios.put(`${settings.apiUrls.catalogue}${id}`,
      data,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  deleteCatalogue(id, token) {
    return axios.delete(`${settings.apiUrls.catalogue}${id}`,
      {
        headers: {
          RecaptchaToken: token
        }
      })
  },
  getCatalogue(id) {
    return axios.get(`${settings.apiUrls.catalogue}${id}`)
  },
  addFavorites({ ids, type }) {
    return axios.post(settings.apiUrls.favorites.items,
      { ids, type })
  },
  removeFavorites({ ids, type }) {
    return axios.delete(settings.apiUrls.favorites.items,
      {
        data: {
          ids, type
        }
      })
  },
  migrateFavorites(favorites) {
    return axios.post(settings.apiUrls.favorites.migrate, favorites)
  },
  deleteSUPProfile() {
    return axios.delete(settings.apiUrls.mySettings.deleteProfile)
  },
  getUserData(includeCatalogues) {
    return axios.get(settings.apiUrls.user.data, {
      params: {
        includeCatalogues
      }
    })
  },
  getProductsPackageInfo(eansArray) {
    const eans = eansArray.join('|')
    return axios.get(settings.apiUrls.user.packageInfo, {
      params: {
        eans
      }
    })
  },
  getCatalogues({
    sorting = null
  }) {
    const params = {
      sorting
    }
    return axios.get(settings.apiUrls.user.favorites.catalogues, { params })
  }
}
