<template>
  <tr class="product-table-item">
    <td class="cell--name">
      <div class="product-table-item__info">
        <a
          :href="model.url"
          class="product-table-item__info-link"
        >
          <span class="product-image">
            <img
              v-if="thumbnail"
              ref="img"
              :src="thumbnail"
              :alt="model.name"
              class="product-image__thumbnail"
            >
          </span>
          <span class="product-table-item__info__text">
            <span class="product-table-item__brand">{{ model.brand }}</span>
            <span class="product-table-item__title">{{ model.name }}</span>
            <span class="product-table-item__subtitle">{{ model.weightAndVolume }}</span>
          </span>
        </a>
      </div>

      <template v-if="isMobile">
        <buy-button-card
          class="item-card__controls--mobile"
          :model="model"
          :placement="listName"
          :translations="buyButtonTranslations"
        />

        <wholesaler
          :popup-model="productWholesalerModel"
          :ean="model.ean"
          :code="model.code"
          :name="model.name"
          :placement="listName"
          button-type="link"
        />
      </template>
    </td>
    <td
      v-if="!isMobile"
      class="cell--volume"
    >
      <span class="product-table-item__subtitle">{{ model.weightAndVolume }}</span>
    </td>
    <td
      v-if="!isMobile"
      class="cell--controls"
    >
      <div class="product-table-item__actions">
        <buy-button-card
          :model="model"
          :placement="listName"
          :translations="buyButtonTranslations"
        />

        <wholesaler
          :popup-model="productWholesalerModel"
          :ean="model.ean"
          :code="model.code"
          :name="model.name"
          :placement="listName"
          button-type="link"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import impressionTracking from '@/helpers/ImpressionTracking'
import gtmHelper from '@/helpers/GtmHelper'
import epiTracking from '@/helpers/epi-tracking'

import BuyButtonCard from '@/components/products/buy-button-card.vue'
import Wholesaler from '@/components/products/wholesaler.vue'

const matchBreakpoint = () => window.matchMedia('(max-width: 568px)').matches

export default {
  components: {
    BuyButtonCard,
    Wholesaler
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      buyButtonTranslations: window.arla.cms.resources.buyButton,
      productWholesalerModel: window.arla.cms.resources.productWholesaler,
      isMobile: false // TODO: Move this param to the parent or separate helper
    }
  },
  computed: {
    trackData() {
      return {
        position: this.position,
        category: this.category,
        list: this.listName,
        ...this.model
      }
    },
    epiTrackData() {
      return {
        queryTrackId: this.queryTrackId,
        hitId: this.model.hitId,
        position: this.position
      }
    },
    thumbnail() {
      return this.model.image ? this.model.image.replace('px300', 'px75') : null
    }
  },
  mounted() {
    impressionTracking.trackProductImpressions({
      el: this.$el,
      model: this.trackData,
      functionName: 'productImpression'
    })
    this.onResize()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('orientationchange', this.onResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('orientationchange', this.onResize)
  },
  methods: {
    productClick() {
      gtmHelper.productClicks(this.trackData)
      epiTracking.trackProductClick(this.epiTrackData)
    },
    onResize() {
      this.isMobile = matchBreakpoint()
    }
  }
}
</script>
