<template>
  <div>
    <div class="form-row">
      <h4>
        <em>{{ currentStepNumber }}/{{ totalSteps }}</em>
        <br>
        {{ catalogueTranslations.modalCatalogueContactHeading }}
      </h4>
    </div>
    <div class="form-row">
      <label
        for="catalogue-contact-name"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueContactNameHeading }}
      </label>
      <input
        id="catalogue-contact-name"
        v-model="value.contactInfoName"
        :placeholder="catalogueTranslations.modalCatalogueContactNamePlaceholder"
        type="text"
      >
    </div>
    <div class="form-row">
      <label
        for="catalogue-contact-email"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueContactEmailHeading }}
      </label>
      <input
        id="catalogue-contact-email"
        v-model="value.contactInfoEmail"
        :placeholder="catalogueTranslations.modalCatalogueContactEmailPlaceholder"
        type="email"
      >
    </div>
    <div class="form-row">
      <label
        for="catalogue-contact-phone"
        class="form-label"
      >
        {{ catalogueTranslations.modalCatalogueContactPhoneHeading }}
      </label>
      <input
        id="catalogue-contact-phone"
        v-model="value.contactInfoPhone"
        :placeholder="catalogueTranslations.modalCatalogueContactPhonePlaceholder"
        pattern="^[0-9]{9,12}$"
        type="tel"
      >
    </div>
  </div>
</template>

<script>
import useCatalogue from '@/compositions/useCatalogue'
import gtmHelper from '@/helpers/GtmHelper'
import useWizardFlow from '@/compositions/catalogue/useWizardFlow'

export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  setup() {
    const {
      state: {
        totalSteps
      },
      currentStepNumber,
      currentStep
    } = useWizardFlow()
    const { catalogueTranslations } = useCatalogue()

    const onFieldChange = (fieldName, value) => {
      gtmHelper.catalogueFieldInput(fieldName, value)
    }

    return {
      catalogueTranslations,
      currentStepNumber,
      currentStep,
      totalSteps,

      onFieldChange
    }
  }
}
</script>
