import 'core-js/stable'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMq from 'vue-mq'
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import VueYouTubeEmbed from 'vue-youtube-embed'
import VTooltip from 'v-tooltip'

// not used inside main
import 'normalize.css'
import '@/stylus/main.styl'

import '@/helpers/detectors'

import infiniteScroll from 'vue-infinite-scroll'
import VModal from 'vue-js-modal'

import ElementIntersection from '@/helpers/element-intersection'

import EpiForms from '@/helpers/EpiserverForms/main'
import gtmTracking from '@/helpers/gtm-tracking'
import loginButtons from '@/helpers/loginButtons'
import copyLink from '@/components/copy-link'
import shareButton from '@/components/share'
import useStickyMenu from '@/compositions/useStickyMenu'
import goTopButton from '@/components/goTopButton'
import notification from '@/components/notification'

// Vue
import GenericVideo from '@/components/generic-video.vue'
import GenericImage from '@/components/generic-image.vue'
import MediaBlock from '@/components/media-block.vue'
import BreakerBlock from '@/components/breaker-block.vue'
import Accordion from '@/components/accordion/accordion.vue'
import BlockSlider from '@/components/block-slider.vue'
import CardSlider from '@/components/card-slider.vue'
import BusinessCaseDetailsSidebar from '@/components/businessCases/business-case-details-sidebar.vue'
import ShowMore from '@/components/show-more.vue'
import AmbientMedia from '@/components/ambient-media.vue'
import AddToFavorites from '@/components/add-to-favorites.vue'
import Tooltip from '@/components/tooltip.vue'

import OrderMaterialCheckout from '@/components/orderMaterials/order-material-checkout.vue'
import BuyButton from '@/components/products/buy-button.vue'
import Wholesaler from '@/components/products/wholesaler.vue'
import WholesalerModal from '@/components/products/wholesaler-modal.vue'

import Header from '@/components/header/header.vue'

import RecipeTabs from '@/components/recipes/recipe-page/recipe-tabs.vue'

import RecipeIngredients from '@/components/recipes/recipe-page/recipe-tabs-ingredients.vue'
import RecipeNutrition from '@/components/recipes/recipe-page/recipe-tabs-nutrition.vue'
import RelatedRecipes from '@/components/recipes/related-recipes.vue'

import InspirationBlock from '@/components/inspiration-block.vue'

import SearchPage from '@/components/search/search-page.vue'
import CataloguePage from '@/components/catalogue/catalogue-page.vue'

import FavoritesPage from '@/components/favorites/favorites-page.vue'
import SiteMessages from '@/components/site-messages.vue'

import InvisibleRecaptchaElementBlock from '@/components/formElements/invisible-recaptcha-element-block.vue'
import PermissionElementBlock from '@/components/formElements/permission-element-block.vue'

import Affix from '@/components/affix.vue'
import InfoPopup from '@/components/infoPopup/InfoPopup.vue'

import ItemsOverview from '@/components/itemsOverview/items-overview.vue'
import UserCockpitLoader from '@/components/userCockpit/user-cockpit-loader.vue'
import DeleteUserProfile from '@/components/userCockpit/delete-user-profile.vue'
import SustainableModalWrapper from '@/components/sustainable-modal-wrapper.vue'

import vueUtil from '@/vue-util'
import useUser from '@/compositions/useUser'
import '@/helpers/VeeValidate'
import favoritesMigrate from '@/components/favorites/favorites-migrate'

NodeList.prototype.forEach = Array.prototype.forEach

Vue.use(VueRouter)
Vue.use(infiniteScroll)
Vue.use(VueYouTubeEmbed)
Vue.use(VModal)
Vue.use(VTooltip, {
  defaultTemplate: '<div class="v-tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
})

Vue.use(VueMq, {
  breakpoints: {
    mobile: 569,
    tablet: 1025,
    desktop: Infinity
  }
})

Vue.component('generic-image', GenericImage)
Vue.component('generic-video', GenericVideo)
Vue.component('media-block', MediaBlock)

useUser()
const vueComponents = {
  GenericVideo,
  GenericImage,
  MediaBlock,
  BreakerBlock,
  Accordion,
  ShowMore,
  AmbientMedia,
  AddToFavorites,

  Header,

  RecipeTabs,
  RecipeIngredients,
  RecipeNutrition,
  RelatedRecipes,

  BlockSlider,
  CardSlider,
  BusinessCaseDetailsSidebar,

  OrderMaterialCheckout,
  BuyButton,
  Wholesaler,
  WholesalerModal,
  SiteMessages,
  InspirationBlock,
  SearchPage,
  CataloguePage,
  FavoritesPage,
  InvisibleRecaptchaElementBlock,
  PermissionElementBlock,
  Affix,
  InfoPopup,
  ItemsOverview,
  Tooltip,
  UserCockpitLoader,
  DeleteUserProfile,
  SustainableModalWrapper
}

// Modules
const epiForms = new EpiForms()

// Helpers

// And all Vue components on the page
vueUtil.mountComponents(vueComponents)

// Init Modules
gtmTracking()
loginButtons()
copyLink()
shareButton()
useStickyMenu()
goTopButton()
notification()

const selectorsToObserve = `
  .page-header,
  .page-hero,
  .breaker-block,
  .article-page .breadcrumbs,
  .article-page__section blockquote,
  .article-page__section figure img,
  .article-page__section .embed-container
`
// eslint-disable-next-line no-unused-vars
const observer = ElementIntersection(selectorsToObserve)

setTimeout(() => {
  epiForms.initialize()
}, 1000)

favoritesMigrate()
