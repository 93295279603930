import { reactive } from 'vue'
import GtmHelper from '@/helpers/GtmHelper'
import api from '@/lib/api'

const state = reactive({
  productIsAdded: false,
  products: [],
  isVisible: false,
  allOrdersUrl: null,
  checkoutUrl: null,
  translations: {},
  cartItemCount: 0,
  hasActiveOrder: false,
  loading: false
})

const timeoutThreshold = 5000
let timeout = null

export default () => {
  const hideMiniCart = () => {
    state.isVisible = false
  }
  const showMiniCart = () => {
    state.isVisible = true

    timeout = setTimeout(() => {
      if (!state.hideOnMouseleave) {
        hideMiniCart()
      }
    }, timeoutThreshold)
  }

  const removeHideTimeout = () => {
    clearTimeout(timeout)
  }

  const updateMiniCartState = (updatedState) => {
    Object.keys(updatedState).forEach((key) => {
      state[key] = updatedState[key]
    })
  }

  const addToMiniCart = ({
    code,
    name,
    brand,
    amount,
    priceUnit,
    image,
    placement
  }) => api.addCartItems({
    articleNumber: code,
    amount
  }).then(({ data }) => {
    if (data.success) {
      const index = state.products.findIndex((product) => product.code === code)
      if (index !== -1) {
        state.products[index].amount = amount
      } else {
        state.products.push({
          code, name, brand, amount, priceUnit, image
        })
      }
      state.cartItemCount = data.cartItemsAmount
      state.hasActiveOrder = true
      showMiniCart()
      GtmHelper.productAddToCart({
        productName: name,
        productAmount: amount,
        unitSelection: priceUnit,
        placement
      })
    } else {
      GtmHelper.productAddToCartError({
        productName: name,
        errorMessage: data.message
      })
    }
  })

  return {
    state,

    updateMiniCartState,
    addToMiniCart,
    showMiniCart,
    hideMiniCart,
    removeHideTimeout
  }
}
