<template>
  <div class="wholesaler-control u-text-nowrap">
    <button
      :class="cssClass"
      @click="onButtonClick"
    >
      <IconArrow class="icon" />
      {{ popupModel.buttonText }}
    </button>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue'
import IconArrow from '@/assets/icons/arrow-corner.svg'
import useModal from '@/compositions/useModal'
import useWholesaler from '@/compositions/useWholesaler'
import GtmHelper from '@/helpers/GtmHelper'

export default {
  components: {
    IconArrow
  },
  props: {
    popupModel: {
      type: Object,
      required: true
    },
    buttonType: {
      type: String,
      default: 'button'
    },
    ean: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    placement: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const modalName = 'wholesaler-modal'

    const { proxy } = getCurrentInstance()
    const { $modal } = proxy
    const {
      showPopup
    } = useModal($modal)

    const {
      setState
    } = useWholesaler()

    const {
      popupModel, name, code, ean, placement
    } = props

    const updatedPopupModel = computed(() => {
      const model = JSON.parse(JSON.stringify(popupModel))
      model.wholesalers.forEach((item) => {
        if (props.code) {
          item.linkUrl = item.linkUrl.replace('{id}', code)
        }

        if (props.ean) {
          item.linkUrl = item.linkUrl.replace('{ean}', ean)
        }
      })

      if (model.emailSubject) {
        const encodedSubject = model.emailSubject.replace('{id}', code).replace('{name}', name)
        model.email = `${model.email}?subject=${encodeURIComponent(encodedSubject)}`
      }

      return model
    })

    const onButtonClick = () => {
      setState({
        newName: modalName,
        newModel: updatedPopupModel,
        productId: code,
        productName: name,
        placement
      })
      GtmHelper.openWholesalerModal({
        productID: code,
        productName: name,
        placement
      })
      setTimeout(() => {
        showPopup(modalName)
      }, 100)
    }

    const cssClass = computed(() => ([
      'cta',
      { 'cta--transparent': props.buttonType === 'button' },
      { 'cta--link': props.buttonType === 'link' }
    ]))

    return {
      modalName,
      cssClass,

      onButtonClick
    }
  }
}
</script>
