<template>
  <div class="product-card">
    <a
      :href="model.url"
      class="product-card-link"
      @click="productClick"
    >
      <div class="product-card__image">
        <img
          v-if="model.image"
          ref="img"
          :src="model.image"
          :alt="model.name"
        >
      </div>
      <CatalogueCheckbox
        v-if="isCataloguePage"
        :id="model.ean"
        :name="model.name"
        type="product"
      />
      <div class="product-card__text">
        <span class="product-card__brand">{{ model.brand }}</span>
        <strong class="product-card__title">{{ model.name }}</strong>
        <span class="product-card__subtitle">{{ model.weightAndVolume }}</span>
      </div>
    </a>

    <buy-button-card
      v-if="model.packagesInfo"
      :model="model"
      :placement="listName"
      :translations="buyButtonTranslations"
    />

    <wholesaler
      :popup-model="productWholesalerModel"
      :ean="model.ean"
      :code="model.code"
      :name="model.name"
      :placement="listName"
      button-type="link"
    />

    <add-to-favorites
      :item="{
        id: model.ean,
        name: model.name
      }"
      type="product"
    />
  </div>
</template>

<script>
import impressionTracking from '@/helpers/ImpressionTracking'
import GtmHelper from '@/helpers/GtmHelper'
import epiTracking from '@/helpers/epi-tracking'

import BuyButtonCard from '@/components/products/buy-button-card.vue'
import Wholesaler from '@/components/products/wholesaler.vue'
import AddToFavorites from '@/components/add-to-favorites.vue'
import CatalogueCheckbox from '@/components/catalogue/catalogue-checkbox.vue'
import useCatalogue from '@/compositions/useCatalogue'

export default {
  components: {
    AddToFavorites,
    BuyButtonCard,
    Wholesaler,
    CatalogueCheckbox
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    listName: {
      type: String,
      required: true
    },
    queryTrackId: {
      type: String,
      default: ''
    },
    removeFromFavorites: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { isCataloguePage } = useCatalogue()

    return {
      isCataloguePage
    }
  },
  data() {
    return {
      buyButtonTranslations: window.arla.cms.resources.buyButton,
      productWholesalerModel: window.arla.cms.resources.productWholesaler
    }
  },
  computed: {
    trackData() {
      return {
        position: this.position,
        category: this.model.category,
        list: this.listName,
        ...this.model
      }
    },
    epiTrackData() {
      return {
        queryTrackId: this.queryTrackId,
        hitId: this.model.hitId,
        position: this.position
      }
    }
  },
  mounted() {
    impressionTracking.trackProductImpressions({
      el: this.$el,
      model: this.trackData,
      functionName: 'productImpression'
    })
  },
  methods: {
    productClick() {
      GtmHelper.productClicks(this.trackData)
      epiTracking.trackProductClick(this.epiTrackData)
    }
  }
}
</script>
